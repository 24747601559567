
import { signIn, useSession } from "next-auth/react";

import { FcGoogle } from "react-icons/fc";

interface GoogleLoginButtonProps {
    callbackUrl?: string;
}

export const GoogleLoginButton = ({
    callbackUrl='/', 
}:GoogleLoginButtonProps) => {

    const handleSignIn = async () => {
        await signIn('google', { callbackUrl })
    };

    return (
        <button
            type='button'
            onClick={handleSignIn}
            className={`
                bg-white text-gray-900 border border-gray-900
                rounded-md p-2 w-fit
                border border-gray-300 rounded-md p-2 w-full
            `}
        >
            <div className='flex flex-row gap-2 items-start'>
                <FcGoogle size={24} className="mr-auto"/>
                <p className='mr-auto font-medium'>Continue with Google</p>
            </div>
        </button>
    )
}