import React, { useState, useEffect } from 'react';

interface SimpleInputProps {
    type?: 'text' | 'password' | 'email' | 'number';
    id?: string;
    title?: string;
    description?: string;
    errorMessage?: string;

    value: string | number | undefined;
    onChange: (value: string | number | undefined) => void;
    disabled?: boolean;
    placeholder?: string;
    required?: boolean;
    maxLength?: number;

    className?: string;
    titleClassName?: string;
    inputClassName?: string;
    descriptionClassName?: string;
    onBlur?: () => void;
    onFocus?: () => void;
    size?: string;
}

export const SimpleInput = ({
    type = 'text',
    id,
    title,
    description,
    errorMessage,
    value,
    onChange,
    disabled = false,
    placeholder = 'placeholder',
    required = false,
    maxLength,

    className,
    titleClassName,
    inputClassName,
    descriptionClassName='text-gray-600', 
    onBlur,
    onFocus,
    size='md',
}: SimpleInputProps) => {
    const [inFocus, setInFocus] = useState(false)

    const showErrorStyle = !inFocus && errorMessage;
    const currentLength = value ? value.toString().length : 0;
    
    const borderColor =
        showErrorStyle ?  'border-red-500 bg-red-100' : 
        'border-gray-200 '; 

    return (
        <div className={`flex flex-col gap-y-1 ${className}`}>
            {title && 
                <p
                    className={`
                        ${titleClassName}
                        text-black text-sm font-medium
                    `}
                >
                    {title} {required ? <span className="text-red-500">*</span> : <></>}
                </p>
            }
            
            {description && (
                <p className={`text-sm text-gray-600 ${descriptionClassName}`}>
                    {description}
                </p>
            )}

            <input
                id={id ? id : title ? title : undefined}
                name={title}
                type={type}
                placeholder={value ? placeholder : placeholder}

                value={value ? value : ''}
                onChange={(e) => onChange(e.target.value)}
                disabled={disabled}
                onBlur={() => {
                    setInFocus(false);
                    onBlur && onBlur();
                }}
                onFocus={() => {
                    setInFocus(true);
                    onFocus && onFocus();
                }}
                maxLength={maxLength}

                className={`
                    border ${borderColor}
                    ${size=='sm' ? 'text-sm' : 'text-md'}
                    placeholder-gray-500
                    py-2 px-3 focus:bg-white 
                    rounded-md ${inputClassName}
                    ${disabled ? 'text-gray-400 bg-gray-100' : 'text-gray-900 hover:border-blue-500'}
                `}
            />
            {maxLength && (
                <p className="text-sm text-gray-500 ml-auto">
                    {currentLength}/{maxLength}
                </p>
            )}

            {   errorMessage && (
                <p
                    className={`text-sm ml-2 text-red-500 border-1 `}
                    >{errorMessage}
                </p>
            )}

        </div>
    );
};
