

import { useRouter } from "next/router";

import { SimpleButton } from "@/src/atoms-v3/button/SimpleButton"

interface ResetPasswordSentProps {
}

export const ResetPasswordSent = ({
}:ResetPasswordSentProps) => {
    const router = useRouter()

    return (
        <div className='h-full w-full flex flex-col items-center gap-6 p-6 sm:p-10'>
            <div className='flex flex-col gap-1 items-center w-full'>
                <h2>
                    Password Reset Email Sent
                </h2>
                <p className='text-gray-500'>
                    We sent an email to example@example.com. Follow the link to reset your password
                </p>
            </div>

            <SimpleButton
                onClick={() => router.push('/')}
                color='blue' bg='solid'
                className='w-full'
            >
                Go to Home
            </SimpleButton>
        </div>
    )
}