

import axios from 'axios';
import { HiArrowSmLeft } from "react-icons/hi";

import { SimpleButton } from "@/src/atoms-v3/button/SimpleButton"
import {SimpleInput} from "@/src/atoms-v3/input/SimpleInput";

interface SignupFormProps {
    password: string;
    setPassword: (password: string) => void;
    passwordConfirmation: string;
    setPasswordConfirmation: (password: string) => void;
    errors: {
        password: string;
        passwordConfirmation: string;
    }
    submitHandler: (e:React.SyntheticEvent) => void;
    backHandler: () => void;
}

export const SignupForm = ({
    password, setPassword,
    passwordConfirmation, setPasswordConfirmation,
    errors,
    submitHandler,
    backHandler
}:SignupFormProps) => {

    return (
        <form 
            onSubmit={(e) => submitHandler(e)}
            className='h-full w-full flex flex-col items-center gap-6 p-6 sm:p-10'
        >
            <div className='flex flex-col gap-1 items-center w-full'>
                <div 
                    className='flex flex-row gap-1 items-center cursor-pointer w-full'
                    onClick={backHandler}
                >
                    <HiArrowSmLeft size={24}/>
                    <p>Back</p>
                </div>
                <h2>
                    Create a new account
                </h2>
                <p className='text-gray-500'>
                    Create a password for your new account
                </p>
            </div>

            <div className='w-full flex flex-col gap-3'>
                <SimpleInput 
                    title='Password'
                    type='password'
                    placeholder='At least 8 characters'
                    value={password}
                    onChange={(val) => setPassword(val as string)}
                    errorMessage={errors.password}
                />
                <SimpleInput 
                    title='Password Confirmation'
                    type='password'
                    placeholder='Enter your password again'
                    value={passwordConfirmation}
                    onChange={(val) => setPasswordConfirmation(val as string)}
                    errorMessage={errors.passwordConfirmation}
                />
            </div>

            <SimpleButton
                isSubmit={true}
                color='blue' bg='solid'
                className='w-full'
            >
                Continue
            </SimpleButton>
        </form>
    )
}