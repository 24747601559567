
import {useState, useEffect} from 'react'
import { useRouter } from "next/router";
import axios from 'axios';
import { useSession } from 'next-auth/react';

import { SimpleButton } from "@/src/atoms-v3/button/SimpleButton"
import { GoogleLoginButton } from "@/src/atoms-v3/button/GoogleLoginButton";
import { FcGoogle } from "react-icons/fc";
import { HiOutlineMail } from "react-icons/hi";

//redux
import { useAppSelector } from '@/src/hooks/useSelector';
import { useAppDispatch } from '@/src/hooks/useDispatch';
import { userLogin, setUserData } from '@/store/slices/userSlice';
import { organizerLogin } from '@/store/slices/organizerSlice';


interface SelectLoginMethodFormProps {
    nextHandler: () => void;
}

export const SelectLoginMethodForm = ({
    nextHandler
}:SelectLoginMethodFormProps) => {
    const router = useRouter();
    const dispatch = useAppDispatch();

    // GET USER REDUX STATE & G AUTH SESSION
    const userData = useAppSelector(state => state.user).data
    const {token, google_login} = userData

    // session
    const {data:session} = useSession()



    // get backend url
    const [backendUrl, setBackendUrl] = useState<string>('')
    useEffect(() => {
        fetch('/api/env/backend-url')
        .then(res => res.json())
        .then(data => {
            const {backendUrl} = data;
            setBackendUrl(backendUrl)
        })
        .catch(err => console.error('error getting backend url', err))
    },[])


    /** GOOGLE LOGIN FUNCTION */
    const postGoogleLogin = async (session: any) => {
        let submitData:any = {};
        submitData.accessToken = session.accessToken
        if (session.user) { 
            // submitData.name = session.user.name
            submitData.email = session.user.email
            submitData.first_name = session.user.given_name
            submitData.last_name = session.user.family_name
        }
        const config = { headers: { 'Content-Type': 'Application/json',}}  
        const response = await axios.post(
            `${backendUrl}/api/user/auth/google-login-signup/`,
            submitData, config
        )
        const {status, data} = response
        if (status==201) {
            dispatch(userLogin(data))
            router.push({
                pathname:'/auth/onboarding',
                query: {signupType: 'google'}
            })
        } else if (status==200) {
            dispatch(userLogin(data))
            router.push('/')
        } else {
            // banner error
        }
    }

    useEffect(() => {
        // console.log(session)
        if (backendUrl) {
            if (!token && session) {
                postGoogleLogin(session)
            // } else if (token && router.pathname !== '/auth/onboarding') {
            // } else {
            //     //redirect to homepage
            //     router.push('/')
            }
        }
    },[token, session, backendUrl])



    return (
        <div className='h-full w-full flex flex-col items-center gap-6 p-6 sm:p-10'>
            <div className='flex flex-col gap-1 items-center'>
                <h2>Login or sign up in seconds</h2>
                <p className='text-gray-500'
                    >Create a free account and start taking or creating dance lessons today!
                </p>
            </div>

            {/** SIGN UP & LOGIN BUTTONS */}
            <GoogleLoginButton 
                callbackUrl={router.pathname}
            />
            <SimpleButton
                onClick={() => nextHandler()} 
                className="border border-gray-300 rounded-md p-2 w-full"
            >
                <div className='flex flex-row gap-2 items-start'>
                    <HiOutlineMail size={24} className="mr-auto"/>
                    <p className='mr-auto font-medium'>Continue with Email</p>
                </div>
            </SimpleButton>

            <p className='text-xs sm:text-sm text-gray-600'>{`By signing up, you agree to Ambition’s Terms of Use. Read our Privacy Policy`}</p>


        </div>
    )
}