

import { HiArrowSmLeft } from "react-icons/hi";

import { SimpleButton } from "@/src/atoms-v3/button/SimpleButton"

import {SimpleInput} from "@/src/atoms-v3/input/SimpleInput";

interface EmailFormProps {
    email: string;
    setEmail: (val: string) => void;
    emailError: string;
    checkEmailHandler: (e: React.SyntheticEvent) => void;
    backHandler: () => void;
}

export const EmailForm = ({
    email, setEmail, 
    emailError,
    checkEmailHandler,
    backHandler,
}:EmailFormProps) => {

    return (
        <form 
            onSubmit={(e) => checkEmailHandler(e)}
            className='h-full w-full flex flex-col items-center gap-6 p-6 sm:p-10'
        >
            <div className='flex flex-col gap-1 items-center'>
                <div 
                    className='flex flex-row gap-1 items-center cursor-pointer w-full'
                    onClick={backHandler}
                >
                    <HiArrowSmLeft size={24}/>
                    <p>Back</p>
                </div>
                <h2>Continue with Email</h2>
                <p className='text-gray-500'
                    >We’ll check if you have an account, and help create one if you don’t
                </p>
            </div>

            <div className='w-full'>
                <SimpleInput 
                    title='Email Address'
                    type='email'
                    placeholder='johndoe@example.com'
                    value={email}
                    onChange={(val) => setEmail(val as string)}
                    errorMessage={emailError}
                />
            </div>
                <SimpleButton
                    isSubmit={true}
                    color='blue' bg='solid'
                    className='w-full'
                >
                    Continue
                </SimpleButton>

        </form>
    )
}