

import axios from 'axios';
import { HiArrowSmLeft } from "react-icons/hi";

import { SimpleButton } from "@/src/atoms-v3/button/SimpleButton"
import {SimpleInput} from "@/src/atoms-v3/input/SimpleInput";

interface LoginFormProps {
    password: string;
    setPassword: (password: string) => void;
    submitHandler: (e:React.SyntheticEvent) => void;
    backHandler: () => void;
    forgotPasswordHandler: () => void;
    passwordError?: string;
    errorMessage?: string;
}

export const LoginForm = ({
    password, setPassword,
    submitHandler,
    backHandler,
    forgotPasswordHandler,
    passwordError,
    errorMessage=''
}:LoginFormProps) => {

    return (
        <form 
            onSubmit={(e) => submitHandler(e)}
            className='h-full w-full flex flex-col items-center gap-6 p-6 sm:p-10'
        >
            <div className='flex flex-col gap-1 items-center w-full'>
                <div 
                    className='flex flex-row gap-1 items-center cursor-pointer w-full'
                    onClick={backHandler}
                >
                    <HiArrowSmLeft size={24}/>
                    <p>Back</p>
                </div>
                <h2>
                    Enter Password
                </h2>
                <p className='text-gray-500'>
                    Enter your password to login
                </p>
                <p className='text-red-600'>{errorMessage}</p>
            </div>

            <div className='w-full flex flex-col gap-3'>
                <SimpleInput 
                    title='Password'
                    type='password'
                    placeholder='At least 8 characters'
                    value={password}
                    onChange={(val) => setPassword(val as string)}
                    errorMessage={passwordError}
                />
            </div>

            <SimpleButton
                isSubmit={true}
                color='blue' bg='solid'
                className='w-full'
            >
                Login
            </SimpleButton>

            <div className='w-full flex items-center justify-start'> 
                <p 
                    onClick={() => forgotPasswordHandler()}
                    className='cursor-pointer hover:underline'
                    >Forgot Password?
                </p>
            </div>
        </form>
    )
}