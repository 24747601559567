import React, { useState,useEffect, useRef } from 'react'

import { useRouter } from 'next/router';

//icon
import { RiArrowDropDownLine } from 'react-icons/ri';


import { useMediaQuery } from 'react-responsive';
import { DrawerContainer } from '../../containers/DrawerContainer';



//menus
import { LanguageMenu } from '../LanaguageMenu';
import { UserMainMenu } from './modules/UserMainMenu'

//types
export type LanguageChoices= 'en' | 'jp'

interface UserDrawerMenuProps {
    isVisible: boolean;
    hideDrawerCallback: () => void;
    is_left?: boolean;
    // Button?: JSX.Element;
}


export const UserDrawerMenu = ({
    isVisible,
    hideDrawerCallback,
    is_left=true,
    // Button,
}: UserDrawerMenuProps) => {
    const smallScreen = useMediaQuery({ maxWidth:440 })

    //const dispatch = useAppDispatch();
    const router = useRouter();
    

    /** WHICH MENU STATE */
    type Page = 'main' | 'language'
    const [page, setPage] = useState<Page>('main');
    useEffect(() => {
        setPage('main')
    },[isVisible])
    

    /** RENDER PAGE LIST */
    // const pages = [
    //     {   key: "main", component: (
    //         <MainMenu 
    //             is_left={is_left}
    //             goToLanguageMenu={() => setPage('language')}    
    //         />
    //     )},
    //     {   key: "language", component: (
    //         <LanguageMenu
    //             goToMainMenu={() => setPage('main')}    
    //         />
    //     )},
    // ];


    return (
        <DrawerContainer
            isVisible={isVisible}
            hideDrawerCallback={hideDrawerCallback}
            is_left={is_left}
            containerClassName={smallScreen ? 
                'w-[90vw]' : 'w-[300px]'}
        >
            <div className={`w-full h-full relative overflow-x-hidden`}>
                <div
                    tabIndex={page==='main' ? 0 : -1} // Only make the visible page focusable
                    // tabIndex={-1}
                    style={page==='main' ? {
                        // display: 'none' ,
                        // pointerEvents: 'none',
                    } : {}}
                    className={`
                        h-full w-full absolute top-0
                        transition-transform duration-300 ease-in-out
                        ${
                            page==='main'
                            ? "translate-x-0 opacity-100"
                            : "-translate-x-full opacity-0 pointer-events-none visibility-hidden"
                        }
                `}>
                    <UserMainMenu 
                        is_left={is_left}
                        hideHandler={hideDrawerCallback}
                        goToLanguageMenu={() => setPage('language')}    
                    />
                </div>

                <div
                    tabIndex={page==='language' ? 0 : -1} // Only make the visible page focusable
                    // tabIndex={-1}
                    style={page==='language' ? {
                        // display: 'none' ,
                        // pointerEvents: 'none',
                    } : {}}
                    className={`
                        h-full w-full absolute top-0
                        transition-transform duration-300 ease-in-out
                        ${
                            page==='language'
                            ? "translate-x-0 opacity-100"
                            : "translate-x-full opacity-0 pointer-events-none visibility-hidden"
                        }
                `}>
                    <LanguageMenu
                        hideHandler={hideDrawerCallback}
                        goToMainMenu={() => setPage('main')}    
                    />
                </div>

            </div>
        </DrawerContainer>
    )
}
