import { useEffect, useState } from "react"
import axios from 'axios';
import { useRouter } from "next/router";
import { ModalContainer } from "@/src/components-v3/containers/ModalContainer"

import { SelectLoginMethodForm } from "./modules/SelectLoginMethodForm";
import { EmailForm } from "./modules/EmailForm";
import { SignupForm } from "./modules/SignupForm";
import { LoginForm } from "./modules/LoginForm";
import { ResetPasswordSent } from "./modules/ResetPasswordSent";

import { validateEmail } from "@/src/helpers/validation/validation";

//redux
import { useAppDispatch } from '@/src/hooks/useDispatch';
import { userLogin, setUserData } from '@/store/slices/userSlice';

interface LoginSignupModalProps {
    isVisible: boolean;
    hideModalCallback: () => void;
    hideOverlay?: boolean;
}

export const LoginSignupModal = ({
    isVisible,
    hideModalCallback,
    hideOverlay=false
}:LoginSignupModalProps) => {
    const router = useRouter();
    const dispatch = useAppDispatch();

    // get backend url
    const [backendUrl, setBackendUrl] = useState<string>('')
    useEffect(() => {
        fetch('/api/env/backend-url')
        .then(res => res.json())
        .then(data => {
            const {backendUrl} = data;
            setBackendUrl(backendUrl)
        })
        .catch(err => console.error('error getting backend url', err))
    },[])



    //** CURRENT FORM PAGE */
    type Page = 'select-login-method' | 'email' | 'signup' | 'login' | 'reset-password-sent'
    const [page, setPage] = useState<Page>('select-login-method')
    useEffect(() => {
        if (!isVisible) {
            setPage('select-login-method')
            //reset form
            setEmail(''); setPassword(''); setPasswordConfirmation('');
            setErrors(initialErrors)
        }   
    },[isVisible])


    /** FORM STATES */
    const [email, setEmail] = useState<string>('')
    const [password,setPassword] = useState<string>('')
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')


    /** FORM ERROR STATES */
    const initialErrors = {
        email: '',
        password: '',
        passwordConfirmation: '',
    }
    const [errors, setErrors] = useState(initialErrors)
    useEffect(() => {
        setErrors(initialErrors)
    },[page])

    /** OTHER ERROR MESSAGES */
    const [signupError, setSignupError] = useState<string>('')
    const [loginError, setLoginError] = useState<string>('')

    /** EMAILFORM : CHECK EMAIL HANDLER  */
    const checkEmailHandler = async(e:React.SyntheticEvent) => {
        e.preventDefault();
        //check if email form is valid
        if (!email) {
            setErrors({...errors, email: 'Email is required'})
            return
        } else if (!validateEmail(email)) {
            setErrors({...errors, email: 'Invalid Email'})
            return
        }
        setErrors(initialErrors)

        //load backend url
        if (!backendUrl) { console.error('Backend URL env is missing'); return }

        // check if account with email exists
        const response = await axios.post(
            `${backendUrl}/api/user/auth/check-email/`, 
            {email: email}
        )
        const {status, data} = response
        const {userExists} = data
        if (userExists) {
            setPage("login")
        } else {
            setPage("signup")
        }
    }

    const createAccountHandler = async(e:React.SyntheticEvent) => {
        e.preventDefault();
        // check password
        let errors:any = {}
        if (!password) {    errors.password = 'Password is required' } 
        else if (password.length < 8) {   errors.password = 'Password must be at least 8 characters' }
        // check password confirmation
        if (!passwordConfirmation) {    errors.passwordConfirmation = 'Password Confirmation is required' } 
        else if (password !== passwordConfirmation) {   errors.passwordConfirmation = 'Passwords do not match' }

        if (Object.keys(errors).length > 0) {
            setErrors(errors)
            return
        } 
        setErrors(initialErrors)
        
        // check backend url
        if (!backendUrl) { console.error('Backend URL env is missing'); return; }

        // check if account with email exists
        const response = await axios.post(
            `${backendUrl}/api/user/auth/create-account/`, 
            {
                email: email,
                password: password,
                // add language
            }
        )
        const {status, data} = response
        if (status === 201) {
            console.log('account created')
            // perform login
            dispatch(userLogin(data))
            // redirect to onboarding
            router.push('/auth/onboarding')
        } else {
            console.error('error creating account', data)
        }
    }

    const loginHandler = async(e:React.SyntheticEvent) => {
        e.preventDefault();
        // check password
        if (!password) {    setErrors({...errors, password: 'Password is required'}); return }
        setErrors(initialErrors)

        if (!backendUrl) {  console.error('Backend URL env is missing'); return }

        // check if account with email exists
        const response = await axios.post(
            `${backendUrl}/api/user/auth/login/`, 
            { email: email, password: password }
        )
        const {data} = response
        const {error, status} = data
        if (error) {
            if (error=='email')  {
                setLoginError('User with this email does not exist')
            } else if (error=='password') {
                setLoginError('Incorrect password')
            } else {
                setLoginError('Error logging in')
                console.error('error logging in', response)
            }
        } else {
            dispatch(userLogin(data))
            // redirect to home?
            hideModalCallback()
            router.push('/')
        }
    }


    // forgot password handler
    const forgotPasswordHandler = async() => {
        // send axios request to send reset password email
        // if email is not set, redirect to email page
        if (!email) {
            setPage('email')
            return
        }

        // send API request
        const response = await axios.post(
            `${backendUrl}/api/user/auth/send-password-reset-email/${email}/`, 
        )
        const { status, data } = response

        if (status === 200) {
            console.log('password reset email sent')
            // got to reset password sent page
            setPage('reset-password-sent')
        } else {
            console.error('error sending password reset email', data)
        }
    }


    /** RENDER PAGE LIST */
    const pages = [
        {   key: "select-login-method", component: (
            <SelectLoginMethodForm 
                nextHandler={() => setPage("email")} 
            />
        )},
        {   key: "email", component: (
            <EmailForm
                email={email} setEmail={setEmail}
                checkEmailHandler={checkEmailHandler}
                backHandler={() => setPage("select-login-method")}
                emailError={errors.email}
            />
        )},
        {   key: "signup", component: (
            <SignupForm 
                password={password} setPassword={setPassword}
                passwordConfirmation={passwordConfirmation} setPasswordConfirmation={setPasswordConfirmation}
                backHandler={() => setPage("email")}
                submitHandler={createAccountHandler}
                errors={errors}
            />
        )},
        {   key: "login", component: (
            <LoginForm 
                password={password} setPassword={setPassword}
                backHandler={() => setPage("email")}
                forgotPasswordHandler={forgotPasswordHandler}
                submitHandler={loginHandler}
                passwordError={errors.password}
                errorMessage={loginError}
            />
        )},
        {   key: "reset-password-sent", component: (
            <ResetPasswordSent/>
        )},
    ];
    return (
        <ModalContainer
            isVisible={isVisible}
            hideCloseButton={true}
            hideModalCallback={hideModalCallback}
            parentClassName="w-[100vw] sm:w-[500px] h-[90vh] sm:h-fit sm:min-h-[400px]"
            hideOverlay={hideOverlay}
        >
            <div className={`h-full w-full relative
                ${isVisible ? '' : 'hidden'}
            `}>
                {pages.map(({ key, component }, index) => (
                    <div
                        key={key}
                        // tabIndex={key === page ? 0 : -1} // Only make the visible page focusable
                        style={key !== page ? {
                            display: 'none' ,
                            pointerEvents: 'none',
                        } : {}}

                        className={`
                            h-full w-full absolute top-0
                            transition-transform duration-300 ease-in-out
                        ${
                            key === page 
                            ? "translate-x-0 opacity-100"
                            : "translate-x-full opacity-0 pointer-events-none visibility-hidden"
                        }
                    `}>
                        {component}
                    </div>
                ))}
            </div>
        </ModalContainer>
    )
}


